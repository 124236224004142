var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "search-term" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form",
              attrs: {
                model: _vm.searchInfo,
                size: "small",
                "label-width": "100px",
                "label-position": "left"
              }
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 5 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "平台" } },
                        [
                          _c("platform-select", {
                            attrs: {
                              platformId: _vm.searchInfo.platformId,
                              placeholder: "请选择平台"
                            },
                            on: {
                              "update:platformId": function($event) {
                                return _vm.$set(
                                  _vm.searchInfo,
                                  "platformId",
                                  $event
                                )
                              },
                              "update:platform-id": function($event) {
                                return _vm.$set(
                                  _vm.searchInfo,
                                  "platformId",
                                  $event
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 5 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "租户" } },
                        [
                          _c("user-select", {
                            attrs: { userId: _vm.searchInfo.userId },
                            on: {
                              "update:userId": function($event) {
                                return _vm.$set(
                                  _vm.searchInfo,
                                  "userId",
                                  $event
                                )
                              },
                              "update:user-id": function($event) {
                                return _vm.$set(
                                  _vm.searchInfo,
                                  "userId",
                                  $event
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 5 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "SAAS用户" } },
                        [
                          _c("member-select", {
                            attrs: { opeeUserId: _vm.searchInfo.memberId },
                            on: {
                              "update:opeeUserId": function($event) {
                                return _vm.$set(
                                  _vm.searchInfo,
                                  "memberId",
                                  $event
                                )
                              },
                              "update:opee-user-id": function($event) {
                                return _vm.$set(
                                  _vm.searchInfo,
                                  "memberId",
                                  $event
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 5 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "伙伴" } },
                        [
                          _c("user-select", {
                            attrs: { userId: _vm.searchInfo.userId },
                            on: {
                              "update:userId": function($event) {
                                return _vm.$set(
                                  _vm.searchInfo,
                                  "userId",
                                  $event
                                )
                              },
                              "update:user-id": function($event) {
                                return _vm.$set(
                                  _vm.searchInfo,
                                  "userId",
                                  $event
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "下单时间" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              "default-time": ["00:00:00", "23:59:59"],
                              type: "datetimerange",
                              "range-separator": "至",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              format: "yyyy-MM-dd HH:mm:ss"
                            },
                            model: {
                              value: _vm.createtime,
                              callback: function($$v) {
                                _vm.createtime = $$v
                              },
                              expression: "createtime"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "订单号" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入订单号" },
                            model: {
                              value: _vm.searchInfo.parentOrderId,
                              callback: function($$v) {
                                _vm.$set(_vm.searchInfo, "parentOrderId", $$v)
                              },
                              expression: "searchInfo.parentOrderId"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "64px" },
                      attrs: { type: "primary" },
                      on: { click: _vm.onSubmit }
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "64px" },
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.onFilter(false, 1)
                        }
                      }
                    },
                    [_vm._v("导出")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "64px" },
                      attrs: { type: "default" },
                      on: {
                        click: function($event) {
                          return _vm.onFilter(true, 0)
                        }
                      }
                    },
                    [_vm._v("重置")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          ref: "multipleTable",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            "header-cell-style":
              "background-color: #f5f5f5; color: #333; font-weight: bold;",
            stripe: "",
            "tooltip-effect": "dark"
          },
          on: { "selection-change": _vm.handleSelectionChange }
        },
        [
          _c("el-table-column", { attrs: { label: "ID", prop: "ID" } }),
          _c("el-table-column", {
            attrs: { label: "订单号", prop: "parentOrderId", width: "220px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("p", [_vm._v("主：" + _vm._s(scope.row.parentOrderId))]),
                    _c("p", [_vm._v("子：" + _vm._s(scope.row.subOrderId))])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "收益类型", prop: "incomeType" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.incomeType == 1
                      ? _c("el-tag", { attrs: { type: "primary" } }, [
                          _vm._v("我方账号")
                        ])
                      : _vm._e(),
                    scope.row.incomeType == 2
                      ? _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v("租户账号")
                        ])
                      : _vm._e(),
                    scope.row.incomeType == 3
                      ? _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v("外部联盟")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "状态", prop: "status" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.status == 1
                      ? _c("el-tag", [_vm._v("待支付")])
                      : _vm._e(),
                    scope.row.status == 2
                      ? _c("el-tag", { attrs: { type: "default" } }, [
                          _vm._v("已付款")
                        ])
                      : _vm._e(),
                    scope.row.status == 3
                      ? _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v("确认收货")
                        ])
                      : _vm._e(),
                    scope.row.status == 4
                      ? _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v("联盟结算")
                        ])
                      : _vm._e(),
                    scope.row.status == 5
                      ? _c("el-tag", { attrs: { type: "danger" } }, [
                          _vm._v("退货")
                        ])
                      : _vm._e(),
                    scope.row.status == 6
                      ? _c("el-tag", { attrs: { type: "danger" } }, [
                          _vm._v("维权")
                        ])
                      : _vm._e(),
                    scope.row.status == 7
                      ? _c("el-tag", { attrs: { type: "info" } }, [
                          _vm._v("无效")
                        ])
                      : _vm._e(),
                    scope.row.status == 8
                      ? _c("el-tag", { attrs: { type: "danger" } }, [
                          _vm._v("违规")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "平台信息", "min-width": "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("p", [
                      _vm._v("平台ID：" + _vm._s(scope.row.platformId))
                    ]),
                    _c("p", [
                      _vm._v("平台名称：" + _vm._s(scope.row.platformName))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "活动信息", "min-width": "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("p", [
                      _vm._v("活动ID：" + _vm._s(scope.row.subPlatformId))
                    ]),
                    _c("p", [
                      _vm._v("活动名称：" + _vm._s(scope.row.subPlatformName))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "商品信息", prop: "title", width: "200px" }
          }),
          _c("el-table-column", {
            attrs: { label: "付款金额", prop: "payPrice" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.row.payPrice.toFixed(2)) + " 元 ")
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "佣金比", prop: "commissionRate" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s((scope.row.commissionRate * 100).toFixed(2)) +
                        " % "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "佣金信息", "min-width": "135px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("p", [
                      _vm._v("预估佣金：¥ " + _vm._s(scope.row.preCommission))
                    ]),
                    _c("p", [
                      _vm._v("结算佣金：¥ " + _vm._s(scope.row.commission))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "我方收益", "min-width": "135px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("p", [
                      _vm._v("预估收益：¥ " + _vm._s(scope.row.preIncome))
                    ]),
                    _c("p", [_vm._v("结算收益：¥ " + _vm._s(scope.row.income))])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "服务费率", "min-width": "125px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("p", [
                      _vm._v(
                        "三方：" +
                          _vm._s(
                            (scope.row.thirdServiceRatio * 100).toFixed(2)
                          ) +
                          " % "
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        "我方：" +
                          _vm._s((scope.row.serviceRatio * 100).toFixed(2)) +
                          " % "
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        "合伙：" +
                          _vm._s((scope.row.partnerRatio * 100).toFixed(2)) +
                          " % "
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "服务费", "min-width": "235px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("p", [
                      _vm._v(
                        "三方预估：¥" +
                          _vm._s(scope.row.preThirdServiceFee) +
                          " 三方结算：¥" +
                          _vm._s(scope.row.thirdServiceFee) +
                          " "
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        "我方预估：¥" +
                          _vm._s(scope.row.preServiceFee) +
                          " 我方结算：¥" +
                          _vm._s(scope.row.serviceFee) +
                          " "
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        "合伙预估：¥" +
                          _vm._s(scope.row.prePartnerFee) +
                          " 合伙结算：¥" +
                          _vm._s(scope.row.partnerFee) +
                          " "
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "租户佣金比",
              prop: "commissionRate",
              "min-width": "100px"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          (scope.row.userCommissionRate * 100).toFixed(2)
                        ) +
                        " % "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "租户佣金", "min-width": "135px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("p", [
                      _vm._v(
                        "预估佣金：¥ " + _vm._s(scope.row.userPreCommission)
                      )
                    ]),
                    _c("p", [
                      _vm._v("结算佣金：¥ " + _vm._s(scope.row.userCommission))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "租户收益", "min-width": "135px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("p", [
                      _vm._v("预估收益：¥ " + _vm._s(scope.row.userPreIncome))
                    ]),
                    _c("p", [
                      _vm._v("结算收益：¥ " + _vm._s(scope.row.userIncome))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "返利信息", "min-width": "135px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("p", [
                      _vm._v(
                        "预估返利：¥ " + _vm._s(scope.row.preRebateCommission)
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        "结算返利：¥ " + _vm._s(scope.row.rebateCommission)
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "下单信息", prop: "userId", "min-width": "200px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-tag", [
                      _vm._v(
                        _vm._s(
                          scope.row.user.name
                            ? scope.row.user.name
                            : scope.row.user.phone
                        )
                      )
                    ]),
                    _c("el-tag", { attrs: { type: "success" } }, [
                      _vm._v(_vm._s(scope.row.app ? scope.row.app.name : ""))
                    ]),
                    scope.row.member.ID > 0
                      ? _c("el-tag", [
                          _vm._v(
                            _vm._s(scope.row.member.ID) +
                              " " +
                              _vm._s(scope.row.member.Name)
                          )
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "时间信息",
              prop: "createTime",
              "min-width": "260"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("p", [
                      _vm._v(
                        "下单时间：" +
                          _vm._s(_vm._f("formatDate")(scope.row.createTime))
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        "收货时间：" +
                          _vm._s(_vm._f("formatDate")(scope.row.receiveTime))
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        "更新时间：" +
                          _vm._s(_vm._f("formatDate")(scope.row.CreatedAt))
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "数据版本", prop: "dataVersion" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(" " + _vm._s(scope.row.dataVersion) + " ")]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作", "min-width": "280px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "table-button",
                        attrs: { size: "small", type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.orderDetail(scope.row)
                          }
                        }
                      },
                      [_vm._v("详情")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "table-button",
                        attrs: { size: "small", type: "info" },
                        on: {
                          click: function($event) {
                            return _vm.doOrderSync(scope.row)
                          }
                        }
                      },
                      [_vm._v("同步")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "table-button",
                        attrs: { size: "small", type: "warning" },
                        on: {
                          click: function($event) {
                            return _vm.doOrderRebate(scope.row)
                          }
                        }
                      },
                      [_vm._v("分佣")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "table-button",
                        attrs: { size: "small", type: "danger" },
                        on: {
                          click: function($event) {
                            return _vm.orderDetail(scope.row)
                          }
                        }
                      },
                      [_vm._v("锁定")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        style: { float: "right", padding: "20px" },
        attrs: {
          "current-page": _vm.page,
          "page-size": _vm.pageSize,
          "page-sizes": [10, 30, 50, 100],
          total: _vm.total,
          layout: "total, sizes, prev, pager, next, jumper"
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }